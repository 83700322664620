import styled from "styled-components";
import { fontSize, space, variant } from "styled-system";

const badgeStyle = variant({
  key: "badges",
});

export default styled.span`
  background: ${props => props.theme.colors.primary[0]};
  color: ${({ theme }) => theme.colors.white};
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  align-self: center;
  ${fontSize};
  ${space};
  ${badgeStyle};
`;
