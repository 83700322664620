import React from "react";
import Modal from "app/components/Modal";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { Formik, Form } from "formik";
import InputPassword from "app/components/Input/InputPassword";
import { Link } from "react-router-dom";
import Button from "app/components/Button";
import { login } from "modules/auth/actions";
import PropTypes from "prop-types";
import Alert from "app/components/Alert";
import ButtonWithLoader from "./ButtonWithLoader";

const button = css`
  width: 150px;
  margin-right: 10px;
`;

const StyledButton = styled(Button)`
  ${button}
`;

const StyledButtonWithLoader = styled(ButtonWithLoader)`
  ${button}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const H3 = styled.h3`
  font-size: 18px;
`;

class PasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      success: null,
    };

    this.authenticate = this.authenticate.bind(this);
  }

  authenticate(values) {
    const { authenticate, closeModal } = this.props;

    this.setState({ loading: true }, () => {
      authenticate(values)
        .then(() => {
          this.setState({
            error: null,
            success: { message: "Successfully authenticated." },
            loading: false,
          });

          closeModal();
        })
        .catch(error => {
          this.setState({ error, success: null, loading: false });
        });
    });
  }

  render() {
    const { auth, closeModal, isOpen } = this.props;
    const { error, success, loading } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onBackgroundClick={closeModal}
        onEscapeKeydown={closeModal}
      >
        <H3>Enter your password to save changes:</H3>
        <Formik
          initialValues={{ email: auth.email, password: "" }}
          onSubmit={this.authenticate}
          render={({ touched, errors, handleChange, values }) => (
            <Form>
              <InputPassword
                onChange={handleChange}
                errors={errors}
                touched={touched}
                required
              />
              <Link to="/password-reset">
                <small>Forgot your password?</small>
              </Link>
              {error && (
                <Alert
                  variant="danger"
                  closeAlert={() => this.setState({ error: null })}
                >
                  {error.message}
                </Alert>
              )}
              {success && (
                <Alert
                  variant="success"
                  closeAlert={() => this.setState({ success: null })}
                >
                  {success.message}
                </Alert>
              )}
              <ButtonContainer>
                <StyledButton variant="secondary" onClick={closeModal}>
                  Cancel
                </StyledButton>
                <StyledButtonWithLoader
                  loading={loading}
                  disabled={errors.password || !values.password}
                  type="submit"
                >
                  Reauthenticate
                </StyledButtonWithLoader>
              </ButtonContainer>
            </Form>
          )}
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = {
  authenticate: login,
};

PasswordModal.defaultProps = {
  isOpen: false,
};

PasswordModal.propTypes = {
  auth: PropTypes.shape({}).isRequired,
  authenticate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordModal);
