import styled from "styled-components";

export const FlexLeft = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Label = styled.label`
  display: inline-block;
  margin: 20px 0 5px 0;
  font-weight: bold;
  font-size: 14px;
  :first-child {
    margin: 0 0 5px 0;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 10px;
  flex-grow: 0.35;
  flex-shrink: 0.65;
`;

export const SectionWrapper = styled.div`
  margin: 15px 0;
`;
