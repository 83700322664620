import React from "react";
import styled from "styled-components";
import InputEmail from "app/components/Input/InputEmail";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { saveUserEmail } from "modules/user/actions";
import PropTypes from "prop-types";
import LoginModule from "app/components/LoginModule";
import Alert from "app/components/Alert";
import Badge from "app/components/Badge";
import SpanLink from "app/components/SpanLink";
import ButtonWithLoader from "./ButtonWithLoader";
import PasswordModal from "./PasswordModal";
import { InputWrapper, Label, FlexLeft, SectionWrapper } from "./styles";

const SmallRed = styled.small`
  color: ${props => props.theme.colors.red};
`;

class EmailInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      error: null,
      success: null,
    };
  }

  save = email => {
    const { saveEmail } = this.props;

    this.setState({ saving: true }, () => {
      saveEmail(email)
        .then(() => {
          this.setState({
            error: null,
            success: { message: "Successfully updated email." },
            saving: false,
          });
        })
        .catch(error => {
          this.setState({ error, success: null, saving: false });
        });
    });
  };

  render() {
    const { providerData, toggleLinkCredentials, userPrivate } = this.props;
    const { error, saving, success } = this.state;
    const emailProviderData = providerData.find(
      data => data.providerId === "password"
    );
    const otherProviderData = providerData.find(
      data => data.providerId !== "password"
    );

    return (
      <SectionWrapper>
        <Formik
          initialValues={{
            email: userPrivate.email,
          }}
          enableReinitialize
          onSubmit={values => this.save(values.email)}
          render={({ touched, errors, handleChange, isValid }) => (
            <Form>
              <Label>
                Email
                <Badge ml={1} fontSize={0} variant="danger">
                  Required
                </Badge>
              </Label>
              <InputWrapper>
                <InputEmail
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  disabled={!emailProviderData}
                  required
                />
              </InputWrapper>
              {error && error.code !== "auth/requires-recent-login" && (
                <Alert
                  variant="danger"
                  closeAlert={() => this.setState({ error: null })}
                >
                  {error.message}
                </Alert>
              )}
              {success && (
                <Alert
                  variant="success"
                  closeAlert={() => this.setState({ success: null })}
                >
                  {success.message}
                </Alert>
              )}
              {!emailProviderData && otherProviderData && (
                <SmallRed>
                  You are currently linked with another provider. Click
                  <SpanLink onClick={() => toggleLinkCredentials(true)}>
                    {" "}
                    here
                  </SpanLink>{" "}
                  to create an email login.
                </SmallRed>
              )}
              <FlexLeft>
                <ButtonWithLoader
                  width="211px"
                  height="32px"
                  disabled={!emailProviderData || !isValid}
                  loading={saving}
                  type="submit"
                >
                  Update Email
                </ButtonWithLoader>
              </FlexLeft>
            </Form>
          )}
        />
        <PasswordModal
          isOpen={error && error.code === "auth/requires-recent-login"}
          closeModal={() => this.setState({ saving: false, error: null })}
        >
          <LoginModule />
        </PasswordModal>
      </SectionWrapper>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  userPrivate: user.private,
});

const mapDispatchToProps = {
  saveEmail: saveUserEmail,
};

EmailInfo.defaultProps = {
  providerData: [],
};

EmailInfo.propTypes = {
  providerData: PropTypes.instanceOf(Array),
  saveEmail: PropTypes.func.isRequired,
  toggleLinkCredentials: PropTypes.func.isRequired,
  userPrivate: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailInfo);
