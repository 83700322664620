import React, { useEffect } from "react";
import styled from "styled-components";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  login as loginWithEmail,
  loginWithProvider,
  resetErr,
} from "modules/auth/actions";
import { md } from "styles/variables";
import FacebookLoginButton from "app/components/Button/LoginButtons/FacebookLoginButton";
import GoogleLoginButton from "app/components/Button/LoginButtons/GoogleLoginButton";
import AppleLoginButton from "app/components/Button/LoginButtons/AppleLoginButton";
import LineThroughText from "app/components/LineThroughText";
import InputEmail from "app/components/Input/InputEmail";
import InputPassword from "app/components/Input/InputPassword";
import Alert from "app/components/Alert";
import Button from "app/components/Button";
import Text from "app/components/Text";

const Wrapper = styled.div`
  text-align: left;
  width: 100%;
  max-width: 400px;
  ${({ theme }) => theme.components.card}
`;

const SignUpWrapper = styled.div`
  margin: ${md} 0;
  text-align: center;
`;

const InputWrapper = styled.div`
  margin-bottom: 10px;
`;

const Login = () => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    // TODO (@tgercek): Address preloading with dynamic imports
    // Register.preload();
  }, []);

  return (
    <Wrapper>
      <div>
        <FacebookLoginButton
          mb={2}
          width="100%"
          onClick={() => dispatch(loginWithProvider("facebook"))}
        />
      </div>
      <div>
        <GoogleLoginButton
          mb={2}
          width="100%"
          onClick={() => dispatch(loginWithProvider("google"))}
        />
      </div>
      <div>
        <AppleLoginButton
          width="100%"
          onClick={() => dispatch(loginWithProvider("apple"))}
        >
          Apple
        </AppleLoginButton>
      </div>
      <LineThroughText>OR</LineThroughText>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={values => {
          dispatch(loginWithEmail(values));
        }}
        render={({ touched, errors, handleChange }) => (
          <Form>
            <InputWrapper>
              <InputEmail
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                required
              />
            </InputWrapper>
            <InputWrapper>
              <InputPassword
                onChange={handleChange}
                errors={errors}
                touched={touched}
                required
              />
            </InputWrapper>
            <Text textAlign="center">
              <Link to="/password-reset">
                <small>Forgot your password?</small>
              </Link>
              <div>
                <Button mt={3} width="100%" type="submit">
                  Log In
                </Button>
              </div>
            </Text>
          </Form>
        )}
      />
      {auth.error && (
        <Alert variant="danger" closeAlert={() => dispatch(resetErr())}>
          {auth.error.message}
        </Alert>
      )}
      <SignUpWrapper>
        Don&apos;t have an account?&nbsp;
        <Link to="/register">Sign Up</Link>
      </SignUpWrapper>
    </Wrapper>
  );
};

export default Login;
